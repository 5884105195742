.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-section {
    background-color: #ffffffee;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    
    width: 80%;
}

.privacy-section h1, .privacy-section h2 {
    margin-top: 0;
}

.privacy-section ul {
    padding-left: 20px;
}

.privacy-section ul li {
    margin-bottom: 10px;
}
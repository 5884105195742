.navbar-pastel-green {
  background-color: #94a59c00 !important; /* Pastel green background */
  border-color: #94a59c00 !important; /* Pastel green border, if needed */
  padding: 0rem 1rem; /* Padding around the navbar items */
}

.navbar-pastel-green .navbar-brand,
.navbar-pastel-green .nav-link {
  color: #ffffff; /* White text color */
  text-shadow: 1px 1px 2px #000000; /* Dark outline/projected shadow */
  text-size-adjust: 150%; /* Adjust text size */
}

.navbar-pastel-green .nav-link.active {
  color: #ffffff; /* White text color */
  text-shadow: 1px 1px 2px #000000; /* Dark outline/projected shadow */
  font-weight: bold; /* Make the selected page bold */
}
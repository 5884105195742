.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-section, .featured-apartments, .cta-section, .testimonials-section {
  width: 80%;
  text-align: center;
  margin-bottom: 10px;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  background-color: #ffffffee;
}

.logo-section img {
  width: 20vw; /* Adjust the width as a percentage of the viewport width */
  height: 20vw; /* Adjust the height to maintain the aspect ratio based on the viewport width */
  max-width: 300px; /* Optional: Set a max-width to ensure the image does not get too large */
  max-height: 300px; /* Optional: Set a max-height to ensure the image does not get too large */
  min-width: 100px; /* Optional: Set a min-width to ensure the image does not get too small */
  min-height: 100px; /* Optional: Set a min-height to ensure the image does not get too small */
  border-radius: 20px;
  margin-top: 200px;
  margin-bottom: 200px;
}

.welcome-section {
  margin-top: 10px;
}

.cta-button {
  background-color: #007bff;
}

.airbnb-button {
  background-color: #ff5a5f;
}

.cta-button, .airbnb-button {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px; /* Reduced margin */
  padding: 10px 20px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .cta-button, .airbnb-button {
    width: auto;
    margin-bottom: 0;
  }
}
.cta-button:hover {
  background-color: #0056b3;
}

.row {
  margin-bottom: 10px;
}

hr {
  border: 0;
  height: 0px;
  background: #000;
}

cite {
  font-style: italic;
  font-weight: bold;
}
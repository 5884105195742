.footer {
  padding: 20px;
  background-color: #ffbf7c; /* Pastel green background */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between columns */
}

.footer-content > section {
  flex: 1; /* Each section takes equal width */
  min-width: 0; /* Allows the text to not overflow its container */
  display: flex; /* Use Flexbox for centering */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-content > section {
    margin-bottom: 20px; /* Add some space between vertically stacked sections */
  }
}

h3 {
  color: #000000; /* Adjusted to a darker green for better contrast */
}

a {
  color: #000000; /* Adjusted green color for better contrast */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #000000; /* Adjusted to a darker shade of green for the border */
  padding-top: 10px;
}
/* In your global or component-specific CSS file */
.card-img-custom {
  height: 200px; /* Set your desired height */
  object-fit: cover; /* This will ensure the image covers the area without distorting aspect ratio */
  width: 100%; /* Set your desired width */
}

.card {
  text-align: center;
}

.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  transition: box-shadow 0.3s, transform 0.3s;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title, .card-text {
  text-align: center;
}


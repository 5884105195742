.featured-apartments .apartments-scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
  position: relative; /* Ensures proper stacking context */
  z-index: 1; /* Lower z-index than the buttons */
  /* Consider adding right padding if the button covers content */
}

.featured-apartments {
  position: relative; /* Add this line to ensure the buttons are positioned relative to this container */
}

.scroll-left, .scroll-right {
  position: absolute;
  top: 50%; /* Keeps the button vertically centered */
  transform: translateY(-50%); /* Adjusts the button to be exactly in the middle vertically */
  color: #888;
  background-color: rgba(136, 136, 136, 0); /* Makes the background completely transparent */
  outline: none; /* Removes the default focus outline */
  z-index: 10;
  /* Make bold and larger for better visibility */
  font-weight: bold;
  font-size: 1.5rem;
}

.scroll-left {
  left: 0; /* Positions the left button at the very beginning of the container */
}

.scroll-right {
  right: 0; /* Positions the right button at the very end of the container */
}

/* Hide arrows on screens smaller than 768px */
@media (max-width: 768px) {
  .scroll-left, .scroll-right {
    display: none;
  }
}
/* Optionally, add focus styles for accessibility */
.scroll-left:hover, .scroll-right:hover {
  outline: none; /* Removes the default focus outline */
  background-color: rgba(136, 136, 136, 0); /* Slightly opaque background on hover */
  color: #444; /* Darker color on hover */
}

/* Styles for scrollbar for Chrome, Edge, and Safari */
.featured-apartments .apartments-scroll-container::-webkit-scrollbar {
    height: 8px;
}

.featured-apartments .apartments-scroll-container::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.featured-apartments .apartments-scroll-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}